<template>
  <b-container fluid class="mb-3">
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between mb-4">
          <h4>Produtos</h4>
          <search-input
            v-model="searchProduct"
            :is-loading-search="isLoadingSearch"
            @enter="search"
          />
        </div>
        <b-card-text class="d-flex flex-column">
          <b-table
            class="lvj-table"
            :items="products"
            :fields="fields"
            :busy="isLoading || isLoadingSearch"
            bordered
            striped
            hover
            @row-clicked="changeRoute"
          >
            <template #cell(ativo)="row">
              {{ row.item.ativo ? "Ativo" : "Desativado" }}
            </template>

            <template #cell(actions)="row">
              <b-button
                variant="primary"
                size="sm"
                class="mr-2"
                @click="changeRoute(row.item)"
              >
                <b-icon icon="pencil" />
              </b-button>
              <b-button
                :variant="row.item.exibir ? 'success' : 'danger'"
                size="sm"
                class="mr-2"
                @click="openDeactivateProductModal(row.item)"
              >
                <b-icon icon="eye" />
              </b-button>
              <b-button size="sm" @click="getProductPdf(row.item)" class="mr-2">
                <b-icon-file-earmark-pdf />
              </b-button>
              <a
                :href="`https://lvj.ind.br/produtos/detalhes?id=${row.item.id}`"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-info btn-sm"
              >
                <b-icon-globe-2 />
              </a>
            </template>
          </b-table>
          <b-button @click="getMore">
            <b-icon icon="arrow-clockwise" animation="spin" v-if="isLoading" />
            CARREGAR MAIS
          </b-button>
        </b-card-text>

        <custom-modal
          v-model="showModal"
          :message="modalMessage"
          title="Tem certeza?"
          @confirm="deactivate"
        />
      </b-col>
    </b-row>
    <router-view />
  </b-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CustomModal from "../../components/shared/CustomModal.vue";
import SearchInput from "../../components/shared/SearchInput.vue";
import http from "../../plugins/http";

export default {
  components: { CustomModal, SearchInput },
  name: "productDetails",
  data() {
    return {
      page: 2,
      searchProduct: "",
      modalMessage: "",
      showModal: false,
      productToDeactivate: {},
      fields: [
        {
          key: "codigo_produto",
          label: "Código",
          sortable: true,
          thStyle: {
            width: "90px !important",
          },
        },
        {
          key: "referencia",
          label: "Referência",
          sortable: true,
        },
        {
          key: "descricao_completa",
          label: "Descrição Completa",
          sortable: true,
        },
        {
          key: "actions",
          label: "Ações",
          thStyle: {
            width: "195px !important",
          },
        },
      ],
    };
  },
  computed: {
    ...mapState("product", ["products", "isLoading", "isLoadingSearch"]),
  },
  methods: {
    ...mapActions("product", [
      "getAllProducts",
      "getOneProduct",
      "getMoreProducts",
      "searchProducts",
      "getMoreSearch",
      "hideProduct",
    ]),

    async changeRoute({ id }) {
      await this.getOneProduct(id);
      this.$router.push(`produtos/${id}/editar`);
    },
    async getMore() {
      const { page, searchProduct: search } = this;

      if (this.searchProduct) {
        await this.getMoreSearch({ page, search });
      } else {
        await this.getMoreProducts(page);
      }

      this.page = page + 1;
    },
    async search() {
      this.page = 2;
      if (this.searchProduct) {
        await this.searchProducts(this.searchProduct);
      } else {
        await this.getAllProducts();
      }
    },
    openDeactivateProductModal(product) {
      this.showModal = true;

      if (product.exibir) {
        this.modalMessage = `Deseja deixar de exibir o produto: ${product.codigo_produto}`;
      } else {
        this.modalMessage = `Deseja reexibir o produto: ${product.codigo_produto}`;
      }

      this.productToDeactivate = product;
    },
    async deactivate() {
      const { productToDeactivate } = this;
      const data = {
        id: productToDeactivate.id,
        values: {
          exibir: !productToDeactivate.exibir,
        },
      };

      await this.hideProduct(data);
      this.showModal = false;
    },
    async getProductPdf({ id, codigo_produto }) {
      const { data } = await http.get(`product/generatePDF/${id}`, {
        responseType: "blob",
      });
      const url = URL.createObjectURL(data);
      const a = document.createElement("a");
      a.href = url;
      a.download = `produto - ${codigo_produto}`;
      a.click();
      a.remove();
      setTimeout(() => window.URL.revokeObjectURL(url), 100);
    },
  },
  async created() {
    if (!this.products.length) {
      await this.getAllProducts();
    }
  },
};
</script>

<style lang="scss" scoped>
.lvj-table::v-deep tr th:last-child {
  width: 8%;
}
</style>
